// Initialize Firebase
var firebaseConfig = {
  apiKey: "AIzaSyB5riFt6wlsuwgGeQp_AzYydAbEMTfEZpY",
  authDomain: "crewdriver-af.firebaseapp.com",
  databaseURL: "https://crewdriver-af.firebaseio.com",
  projectId: "crewdriver-af",
  storageBucket: "crewdriver-af.appspot.com",
  messagingSenderId: "123892620940",
  appId: "1:123892620940:web:865e9523a20e02daaba5a9",
  measurementId: "G-DX1LSP5B74"
};
firebase.initializeApp(firebaseConfig);

$(document).ready(function() {
  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      const [displayName, email, uid] = [
        user.displayName,
        user.email,
        user.uid
      ];
      if (
        window.location.pathname !== "/" &&
        window.location.pathname !== "/views/sessions/testDrive.jsp"
      ) {
        setupFields(displayName, email, uid);
      }
    }
  });

  function setupFields(displayName, email, uid) {
    if (displayName.indexOf(" ") > -1) {
      $("#userName").text(displayName.substring(0, displayName.indexOf(" ")));
    } else {
      $("#userName").text(displayName);
    }
    $("#adminName").val(displayName);
    $("#email").val(email);
    $("#uid").val(uid);

    $("#companyName").val(`${displayName}'s Company`);
    $("#companyShort").val(displayName.substring(0, 11));
    const companyPrefix = $("#companyName")
      .val()
      .trim()
      .toUpperCase()
      .substring(0, 4);
    $("#companyPrefix").val(companyPrefix);
  }
});
